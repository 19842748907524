<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <v-tabs v-model="tab" class="wizard-step">
                <v-tab v-for="item in items" :key="item.tab" :to="item.link">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

// import { Money } from "v-money";

export default {
  data() {
    return {
      tab: null,
      items: [
        {
          id: '01',
          tab: this.$t('BREADCRUMBS.BANK'),
          link: '/vipiska/bank'
        },
        {
          id: '02',
          tab: this.$t('BREADCRUMBS.CASH'),
          link: '/vipiska/cash'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('getPaymentOrderTypes')
    this.$store.dispatch('getPaymentSource')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.BANK_KASSA.TITLE') },

      { title: this.$t('MENU.BANK_KASSA.VIPISKA') }
    ])
    this.$store.dispatch('getTotalSum', this.firstItem)
  }
}
</script>
